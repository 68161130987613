import {Side} from '@floating-ui/dom';
import {
  MonorailTracker,
  ShopifyPayModalState,
  LoginWithShopSdkPageName,
  ShopModalHiddenReason,
  ShopModalShownReason,
} from '__deprecated__/common/analytics';
import Bugsnag from '__deprecated__/common/bugsnag';
import {CloseRequestedReason} from '__deprecated__/types/shopSheetModal';

import {ShopPayLogo} from '../shop-pay-logo';
import {
  ATTRIBUTE_ANALYTICS_TRACE_ID,
  ATTRIBUTE_MODAL_BRAND,
} from '../../constants/loginButton';
import WebComponent from '../WebComponent';
import {ShopCloseIcon, ShopLogo} from '../svg';
import {
  PositionBehaviorType,
  PositionOverrides,
  PositionVariant,
  getWindowSize,
  position,
} from '../utils';

import {SHEET_MODAL_HTML, SMALL_SCREEN_WIDTH} from './constants';
import {
  SHEET_MODAL_COMPACT_HTML,
  MODAL_HEADER_CLASS,
  MODAL_HEADERLESS_CLASS,
} from './constants-compact';
import {
  getAnimationDefaultOptions,
  getAnimationModalOptions,
  getClosingKeyframes,
  getDocumentPadding,
  getFadeInKeyframes,
  getFocusableElements,
  getOpeningKeyframes,
  getOverlayClosingKeyframes,
  getOverlayOpeningKeyframes,
} from './shop-sheet-modal.utils';
import {ShopModalHiddenDismissMethod} from '~/types/analytics';

interface CloseParams {
  reason?: ShopModalHiddenReason;
  dismissMethod?: ShopModalHiddenDismissMethod;
}

const ATTRIBUTE_BUSY = 'busy';
const ATTRIBUTE_POPUP_DISABLED = 'disable-popup';
const ATTRIBUTE_MODAL_TITLE = 'title';
export const VALID_ESCAPE_KEYS = ['Escape', 'Esc'];
const ATTRIBUTE_ANCHOR_SELECTOR = 'anchor-to';
const ATTRIBUTE_LIGHT_OVERLAY = 'light-overlay';
const ATTRIBUTE_ANCHOR_POSITION = 'anchor-position';
export const ATTRIBUTE_COMPACT = 'compact';
export const ATTRIBUTE_POSITION_VARIANT = 'position-variant';
export const ATTRIBUTE_HEADERLESS = 'headerless';

export class ShopSheetModal extends WebComponent {
  private _overlay!: HTMLDivElement;
  private _modal!: HTMLDivElement;
  private _closeButton!: HTMLButtonElement;
  private _closableElements!: HTMLElement[];
  private _firstFocusableElement?: HTMLElement;
  private _lastFocusableElement?: HTMLElement;
  private _focusableStartButton!: HTMLButtonElement;
  private _focusableEndButton!: HTMLButtonElement;
  private _windowKeydownListener: ((event: KeyboardEvent) => void) | undefined;
  private _shown = false;
  private _busy = false;
  private _animating = false;
  private _popupDisabled = false;
  private _compact = false;
  private _headerless = false;
  private _anchorElement?: HTMLElement | null;
  private _modalPositioningCleanup?: () => void;
  private _behavior!: PositionBehaviorType;
  private _overFlowPreviousValue = '';
  private _paddingTopPreviousValue = '';
  private _anchorPosition?: Side;
  private _monorailTracker: MonorailTracker | undefined;
  private _positionVariant = PositionVariant.Default;

  static get observedAttributes() {
    return [
      ATTRIBUTE_BUSY,
      ATTRIBUTE_MODAL_TITLE,
      ATTRIBUTE_ANCHOR_SELECTOR,
      ATTRIBUTE_ANALYTICS_TRACE_ID,
      ATTRIBUTE_MODAL_BRAND,
      ATTRIBUTE_LIGHT_OVERLAY,
      ATTRIBUTE_ANCHOR_POSITION,
      ATTRIBUTE_POSITION_VARIANT,
    ];
  }

  constructor() {
    super();

    if (!customElements.get('shop-close-icon')) {
      customElements.define('shop-close-icon', ShopCloseIcon);
    }

    if (!customElements.get('shop-logo')) {
      customElements.define('shop-logo', ShopLogo);
    }

    if (!customElements.get('shop-pay-logo')) {
      customElements.define('shop-pay-logo', ShopPayLogo);
    }

    this._compact = this.getBooleanAttribute(ATTRIBUTE_COMPACT);
    this._positionVariant =
      (this.getAttribute(ATTRIBUTE_POSITION_VARIANT) as PositionVariant) ||
      PositionVariant.Default;
    // make sure headerless does not apply for non-compact modals
    this._headerless =
      this._compact && this.getBooleanAttribute(ATTRIBUTE_HEADERLESS);

    const template = document.createElement('template');
    const templateSource = this._compact
      ? SHEET_MODAL_COMPACT_HTML
      : SHEET_MODAL_HTML;
    template.innerHTML = templateSource;

    this.attachShadow({mode: 'open'}).append(template.content.cloneNode(true));

    if (this._headerless) {
      const header = this.shadowRoot!.querySelector(
        `.${MODAL_HEADER_CLASS}`,
      ) as HTMLDivElement;
      header.classList.add(MODAL_HEADERLESS_CLASS);
    }
  }

  connectedCallback() {
    // init elements
    this._overlay = this.shadowRoot!.querySelector(
      '.sda-overlay',
    ) as HTMLDivElement;

    this._modal = this.shadowRoot!.querySelector(
      '.sda-modal',
    ) as HTMLDivElement;
    // setting overflow hidden so the close button is not visible during the animation
    this._modal.style.overflow = 'hidden';
    this._focusableStartButton =
      this._overlay.querySelector('.focus-trap--start')!;
    this._focusableEndButton = this._overlay.querySelector('.focus-trap--end')!;
    this._popupDisabled = Boolean(this.getAttribute(ATTRIBUTE_POPUP_DISABLED));
    this._modal?.classList.toggle(
      ATTRIBUTE_POPUP_DISABLED,
      this._popupDisabled,
    );

    this._closeButton = this.shadowRoot!.querySelector(
      '.sda-modal-close-button',
    ) as HTMLButtonElement;

    this._closableElements = [this._closeButton, this._overlay];

    // init events
    this._overlay.addEventListener('click', this._handleClick.bind(this));

    if (!this._windowKeydownListener) {
      this._windowKeydownListener = this._handleKeyboard.bind(this);
      window.addEventListener('keydown', this._windowKeydownListener);
    }
  }

  disconnectedCallback() {
    if (this._windowKeydownListener) {
      window.removeEventListener('keydown', this._windowKeydownListener);
    }

    this._modalPositioningCleanup?.();
  }

  attributeChangedCallback(
    name: string,
    _oldValue: string | null,
    newValue: string | null,
  ) {
    switch (name) {
      case ATTRIBUTE_BUSY:
        this._busy = Boolean(newValue);
        break;
      case ATTRIBUTE_POPUP_DISABLED:
        this._popupDisabled = Boolean(newValue);
        this._modal?.classList.toggle(
          ATTRIBUTE_POPUP_DISABLED,
          this._popupDisabled,
        );
        break;
      case ATTRIBUTE_MODAL_TITLE:
        this._modal.setAttribute('aria-label', newValue || '');
        break;
      case ATTRIBUTE_ANCHOR_SELECTOR: {
        const anchorSelector = this.getAttribute(ATTRIBUTE_ANCHOR_SELECTOR);
        if (anchorSelector !== null && anchorSelector !== '') {
          this._anchorElement = document.querySelector(anchorSelector);
        }

        break;
      }
      case ATTRIBUTE_MODAL_BRAND: {
        const brand = this.getAttribute(ATTRIBUTE_MODAL_BRAND);
        const logoElement = this.shadowRoot?.querySelector(
          '.sda-shop-logo',
        ) as HTMLElement;

        if (brand === 'shop-pay') {
          logoElement.innerHTML =
            '<shop-pay-logo aria-hidden="true" role="img" size="large"></shop-pay-logo>';
        } else {
          logoElement.innerHTML =
            '<shop-logo role="img" size="20" color="brand" label="Shop"></shop-logo>';
        }
        break;
      }
      case ATTRIBUTE_LIGHT_OVERLAY: {
        const isLightOverlay = this.hasAttribute(ATTRIBUTE_LIGHT_OVERLAY);
        if (isLightOverlay) {
          this._overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.18)';
        } else {
          this._overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        }
        break;
      }
      case ATTRIBUTE_ANCHOR_POSITION: {
        const anchorPosition = this.getAttribute(ATTRIBUTE_ANCHOR_POSITION);
        if (!anchorPosition) {
          break;
        }

        this._anchorPosition = anchorPosition as Side;
        break;
      }
      case ATTRIBUTE_POSITION_VARIANT: {
        this._positionVariant =
          (this.getAttribute(ATTRIBUTE_POSITION_VARIANT) as PositionVariant) ||
          PositionVariant.Default;
        break;
      }
    }
  }

  setMonorailTracker(tracker: MonorailTracker) {
    this._monorailTracker = tracker;
  }

  async open(reason?: ShopModalShownReason): Promise<Animation | void> {
    if (this._shown) {
      return;
    }

    this._animating = true;
    this._shown = true;
    this._behavior = await this._determineBehavior();
    const options: KeyframeAnimationOptions = {
      ...getAnimationDefaultOptions(this._behavior),
    };

    // starting invisible to give time to the animation to start with a delay
    this._modal.style.opacity = '0';

    const positionOverrides = this._modalOverrides();

    this._overlay.classList.remove('sda-hidden');
    this._overlay.animate(getOverlayOpeningKeyframes(this._behavior), options);

    this._animateDocumentShift(true, options);

    const {cleanup, updatePosition} = position(
      {
        anchorElement: this._anchorElement,
        floatingElement: this._modal,
        overlayElement: this._overlay,
        behavior: this._behavior,
      },
      positionOverrides,
      this._anchorPosition,
    );

    this._modalPositioningCleanup = cleanup;
    // Anecdotally, this needs to go first before we set the overflow to hidden
    // I was seeing some weird behavior where the modal wouldn't be positioned
    // correctly when we updated the position AFTER setting overflow to to hidden
    const positionData = await updatePosition();
    // Lock the page behind the overlay to prevent scrolling so our
    // modal doesn't become detached from the anchor element.
    this._overFlowPreviousValue = document.documentElement.style.overflow;
    document.documentElement.style.overflow = 'hidden';
    if (
      this._behavior === PositionBehaviorType.Dynamic &&
      positionData !== null
    ) {
      const {staticSide, middlewareData} = positionData;
      const {arrow} = middlewareData;

      if (staticSide === 'left' || staticSide === 'right') {
        this._modal.style.transformOrigin = `${staticSide} ${arrow?.y}px`;
      }

      if (staticSide === 'top' || staticSide === 'bottom') {
        this._modal.style.transformOrigin = `${arrow?.x}px ${staticSide}`;
      }
    }

    if (this._monorailTracker) {
      this._monorailTracker.trackShopPayModalStateChange({
        currentState: ShopifyPayModalState.Shown,
        reason,
      });
    } else {
      Bugsnag.notify(new Error('Monorail tracker not set in sheet modal'));
    }

    try {
      const animation = this._modal.animate(
        Object.keys(positionOverrides).length
          ? getFadeInKeyframes()
          : getOpeningKeyframes(this._behavior),
        {
          ...getAnimationModalOptions(this._behavior, options),
        },
      );

      await animation.finished;

      return animation;
    } finally {
      this._animating = false;
      this._modal.style.opacity = '1';
      // everything is animated, but the modal could _possibly_ be out of the viewport depending on parent elements, and styles on the page.
      // e.g., if the body tag has a transformZ applied, the modal will be out of the viewport if the modal is opened while the body is scrolled down.
      this._ensureModalIsInViewport();
      this._setupFocusLock();
    }
  }

  async close({
    reason,
    dismissMethod,
  }: CloseParams = {}): Promise<Animation | void> {
    if (this._busy || !this._shown) {
      return Promise.resolve();
    }

    this._animating = true;
    this._resetFocus();

    const options: KeyframeAnimationOptions = {
      ...getAnimationDefaultOptions(this._behavior),
      // want close duration to be faster than open duration
      duration: 200,
    };

    this._animateDocumentShift(false, options);

    // eslint-disable-next-line promise/catch-or-return
    this._modal
      .animate(getClosingKeyframes(this._behavior), options)
      // setting display none temporarily as the permanent effect will apply with a delay of 200ms
      .finished.finally(() => (this._modal.style.display = 'none'));

    const overlayAnimation = this._overlay.animate(
      getOverlayClosingKeyframes(this._behavior),
      {
        ...options,
      },
    );

    if (this._monorailTracker) {
      this._monorailTracker.trackShopPayModalStateChange({
        currentState: ShopifyPayModalState.Hidden,
        reason,
        dismissMethod,
      });
    } else {
      Bugsnag.notify(new Error('Monorail tracker not set in sheet modal'));
    }

    try {
      await overlayAnimation.finished;
    } finally {
      this._closeOnAnimationFinished();
      this._modalPositioningCleanup?.();
      // undo the evil we did when opening
      document.documentElement.style.overflow = this._overFlowPreviousValue;
    }

    return overlayAnimation;
  }

  onContentLoaded() {
    // setting overflow visible once content is loaded to ensure all content is visible
    this._modal.style.overflow = 'visible';
  }

  setCloseButtonVisibility(show: boolean) {
    this._closeButton.style.display = show ? '' : 'none';
  }

  removeDisplayElements() {
    this.setCloseButtonVisibility(false);
    this._modal.style.cssText = 'border-radius: 0; padding: 0;';

    const logoElement = this.shadowRoot?.querySelector(
      '.sda-shop-logo',
    ) as HTMLElement;
    logoElement.style.display = 'none';

    const sdaLandingElement = this.shadowRoot!.querySelector(
      '.sda-landing',
    ) as HTMLDivElement;
    sdaLandingElement.style.width = '100%';
  }

  private _modalOverrides(): PositionOverrides {
    const variables = getComputedStyle(this._modal);

    // Based on looking at usage between Forms and Arrive (shop.ai)
    // it looks like the use case for setting these variables is to center the modal
    // Forms: https://github.com/Shopify/forms/blob/e4eb31ee9cd9e6b3dac6ce2017104d21aa98435b/component/src/lib/helpers/app-embed-container/SDKPosition.ts#L243
    // Arrive: https://github.com/Shopify/arrive-website/blob/efb29cf9eb0f5edf9bc64071c7889994456fd20c/remix/app/global.css#L270
    // These usages set top + right/left. The presence of those are needed to position the modal in an "overriden" manner.
    // We don't want positioning to change if only a single property is defined.
    const overridesPresent =
      variables.getPropertyValue('--sda-modal-top') &&
      (variables.getPropertyValue('--sda-modal-right') ||
        variables.getPropertyValue('--sda-modal-left'));

    if (!overridesPresent) {
      return {};
    }

    return {
      top: variables.getPropertyValue('--sda-modal-top'),
      right: variables.getPropertyValue('--sda-modal-right') || 'auto',
      bottom: variables.getPropertyValue('--sda-modal-bottom') || 'auto',
      left: variables.getPropertyValue('--sda-modal-left') || 'auto',
    };
  }

  private async _determineBehavior(): Promise<PositionBehaviorType> {
    if (getWindowSize().width <= SMALL_SCREEN_WIDTH || this._popupDisabled) {
      return PositionBehaviorType.Mobile;
    }

    // desktop
    if (this._anchorElement) {
      return PositionBehaviorType.Dynamic;
    }

    // corner variant only works with compact template
    if (this._compact && this._positionVariant === PositionVariant.Corner) {
      return PositionBehaviorType.Corner;
    }

    return PositionBehaviorType.Center;
  }

  private _closeOnAnimationFinished(): void {
    this._animating = false;
    this._shown = false;
    this._modal.style.display = '';
    this._overlay.classList.add('sda-hidden');
  }

  private _handleKeyboard(event: KeyboardEvent): void {
    if (VALID_ESCAPE_KEYS.includes(event.key)) {
      this._handleCloseRequest(event, 'keyboard');
    }
  }

  private _handleClick(event: MouseEvent): void {
    if (this._closableElements.includes(event.target as HTMLElement)) {
      this._handleCloseRequest(
        event,
        event.target === this._closeButton ? 'closeButton' : 'overlay',
      );
    }
  }

  private _handleCloseRequest(
    event: MouseEvent | KeyboardEvent,
    reason: CloseRequestedReason,
  ): void {
    if (!this._shown || this._busy || this._animating) {
      return;
    }

    event.stopPropagation();
    this.dispatchCustomEvent('modalcloserequest', reason);
  }

  private _ensureModalIsInViewport(): void {
    const observer = new IntersectionObserver(async (entries) => {
      for (const entry of entries) {
        const bounds = entry.boundingClientRect;

        if (bounds.top < 0) {
          window.scrollTo({
            top: 0,
            left: 0,
          });
        }

        if (entry.isIntersecting) {
          await this._monorailTracker?.trackPageImpression({
            page: LoginWithShopSdkPageName.AuthorizeModalInViewport,
            allowDuplicates: true,
          });
        }
      }

      observer.disconnect();
    });

    observer.observe(this._modal);
  }

  private _setupFocusLock(): void {
    const focusableElements = [
      this._closeButton,
      ...getFocusableElements(this._modal.querySelector('slot')!),
    ];
    this._firstFocusableElement = focusableElements[0] as HTMLElement;
    this._lastFocusableElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    /**
     * Add type hinting for activeElement as `getRootNode` isn't guaranteed to return a type
     * containing activeElement.
     */
    const rootNode = this.getRootNode() as {
      activeElement?: Element | null;
    } & Node;

    /**
     * Only assign focus to the modal if the modal doesn't already have focus.
     */
    if (
      !rootNode.activeElement?.closest('shop-sheet-modal') ||
      rootNode.activeElement.closest('shop-sheet-modal') !== this
    ) {
      this._modal.focus();
    }
    this._overlay.addEventListener('focus', this._focusHandler, true);
  }

  private _resetFocus(): void {
    this._firstFocusableElement = undefined;
    this._lastFocusableElement = undefined;

    this._overlay.removeEventListener('focus', this._focusHandler, true);
  }

  private _focusHandler = (event: Event) => {
    if (
      this._firstFocusableElement &&
      event.target === this._focusableEndButton
    ) {
      this._firstFocusableElement.focus();
    }

    if (
      this._lastFocusableElement &&
      event.target === this._focusableStartButton
    ) {
      this._lastFocusableElement.focus();
    }
  };

  private _animateDocumentShift(
    opening: boolean,
    options: KeyframeAnimationOptions,
  ): void {
    const documentPadding = getDocumentPadding(this._behavior);
    if (!documentPadding) return;

    if (opening) {
      this._paddingTopPreviousValue = document.documentElement.style.paddingTop;
    }

    const finalValue = opening
      ? documentPadding
      : this._paddingTopPreviousValue;

    // Note: empty string value does not animate correctly so we use 0 if the document does not have padding set
    const keyframes = [{paddingTop: this._paddingTopPreviousValue || '0'}];
    if (opening) {
      keyframes.push({paddingTop: documentPadding});
    } else {
      keyframes.unshift({paddingTop: documentPadding});
    }

    // eslint-disable-next-line promise/catch-or-return
    document.documentElement
      .animate(keyframes, options)
      .finished.finally(() => {
        document.documentElement.style.paddingTop = finalValue;
      });
  }
}

if (!customElements.get('shop-sheet-modal')) {
  customElements.define('shop-sheet-modal', ShopSheetModal);
}

/**
 * Creates a sheet modal element
 * @returns {ShopSheetModal} - The sheet modal element
 */
export function createShopSheetModal(): ShopSheetModal {
  return document.createElement('shop-sheet-modal') as ShopSheetModal;
}
